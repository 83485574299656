import React from "react"
import Layout from "../components/layout"
import QualityPolicies from "../components/politicas-de-calidad/QualityPolicies"
//data
import pageData from "../data/pageData"

const PoliticasCalidad = ({ location }) => (
  <Layout location={location} >
    <QualityPolicies title={pageData.qualityPolicies.title} qualityData={pageData.qualityPolicies.about} items={pageData.qualityPolicies.items} /> 
  </Layout>
)

export default PoliticasCalidad